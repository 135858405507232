<template>
  <section class="container-fluid dash-search-box height d-flex justify-content-center justify-items-center customHome">
    <div>
      <div id="snackbar"></div>

      <div class="col-md-6 mx-auto mb-">
        <div class="row">


<div class="col-md-1"></div>
          <div class="col-md-5 d-flex align-items-center px-0 justify-content-end">
            <div class="h4 text-white mb-0">{{ partnerName }} Partner -&nbsp;  </div>
          </div>
          <div class="col-md-4  partner px-0">
            <div class="h4 text-white mb-0">{{ partnerName !== 'Distributor' ? userRoleName : ''}}</div>
            <v-select class="aba" v-if="partnerName == 'Distributor'" 
            v-model="selectedDistributor" :options.sync="reportObject.distributorList"
            label="DistributorName"            
            :reduce="distributor => distributor.DistributorID"
              placeholder="Distributor Name..."></v-select>
          </div>
<div class="col-md-1"></div>

        </div>
      </div>



      <div class="col-md-11 col-lg-10 mx-auto py-5">
        <div class="dash-tab">
          <div class="col-8 d-flex justify-content-around align-items-center mx-auto text-center bg-white">
            <div v-on:click="onChangeScreening('ALL_SCREENING')" :class="['pointer py-2', this.reportObject.selectedScreening == 'ALL_SCREENING' ? 'active': '']">
              <p class="mb-0">
                <svg width="16.644" height="22.351" viewBox="0 0 16.644 22.351">
                  <g id="Group_281" data-name="Group 281" transform="translate(0 0)">
                    <path id="Path_426" data-name="Path 426" d="M23.322,3a2.587,2.587,0,0,0-2.3,1.427H19.518a.741.741,0,0,0-.713.713v.238H15.713A.742.742,0,0,0,15,6.091V24.637a.741.741,0,0,0,.713.713H30.931a.741.741,0,0,0,.713-.713V6.091a.741.741,0,0,0-.713-.713H27.84V5.14a.741.741,0,0,0-.713-.713H25.618A2.587,2.587,0,0,0,23.322,3Zm0,1.427a1.173,1.173,0,0,1,1.152.884.735.735,0,0,0,.691.542h1.248V6.8H20.231V5.853h1.248a.734.734,0,0,0,.691-.542A1.173,1.173,0,0,1,23.322,4.427ZM16.427,6.8H18.8v.713a.741.741,0,0,0,.713.713h7.609a.741.741,0,0,0,.713-.713V6.8h2.378v17.12H16.427Zm9.451,3.084a.721.721,0,0,0-.476.245L22.5,13.44l-1.367-1.025a.713.713,0,1,0-.862,1.137l1.9,1.427a.739.739,0,0,0,.966-.1l3.329-3.8a.729.729,0,0,0,.112-.8.737.737,0,0,0-.706-.394Zm-7.311,6.665a.713.713,0,1,0,0,1.427h9.511a.713.713,0,1,0,0-1.427Zm0,3.8a.713.713,0,1,0,0,1.427h9.511a.713.713,0,1,0,0-1.427Z"
                      transform="translate(-15 -3)" fill="#000" />
                  </g>
                </svg>
              </p>
              <p class="mb-0 custopmenu">ALL SCREENING</p>
            </div>

            <div v-on:click="onChangeScreening('CONFIRMED_SCREENING')" :class="['pointer py-2', this.reportObject.selectedScreening == 'CONFIRMED_SCREENING' ? 'active': '']">
              <p class="mb-0">
                <svg width="21.841" height="22.126" aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16"
                  role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"></path>
                </svg>
              </p>
              <p class="mb-0 custopmenu">CONFIRMED SCREENING</p>
            </div>

            <div v-on:click="onChangeScreening('NOT_CONFIRMED_SCREENING')" :class="['pointer py-2', this.reportObject.selectedScreening == 'NOT_CONFIRMED_SCREENING' ? 'active': '']">
              <p class="mb-0">
                <svg width="21.841" height="22.126" viewBox="0 0 21.841 22.126">
                  <g id="iconfinder_Wrong_1737371" transform="translate(0 0)">
                    <path id="Path_429" data-name="Path 429" d="M62.569,73.774A11.064,11.064,0,1,0,51.649,62.711,11,11,0,0,0,62.569,73.774Zm0-20.381a9.319,9.319,0,1,1-9.2,9.318A9.269,9.269,0,0,1,62.569,53.393Z"
                      transform="translate(-51.649 -51.648)" />
                    <path id="Path_430" data-name="Path 430" d="M166.841,174.222a.941.941,0,0,0,1.33-.012l2.955-3.012,2.956,3.012a.941.941,0,1,0,1.343-1.318l-2.98-3.036,2.98-3.036a.941.941,0,0,0-1.343-1.318l-2.956,3.011-2.955-3.011a.941.941,0,1,0-1.343,1.318l2.98,3.036-2.98,3.036A.941.941,0,0,0,166.841,174.222Z"
                      transform="translate(-160.206 -158.793)" fill="#000" />
                  </g>
                </svg>
              </p>
              <p class="mb-0 custopmenu">NOT CONFIRMED SCREENING</p>
            </div>
          </div>
        </div>

        <div class="col-12 d-flex justify-content-around align-items-center mx-auto text-center bg-white py-5 round">
          <form class="form-row">
            <div class="col-md-4 my-2">
              <!-- <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text" id="btnGroupAddon2">
                                    <svg width="16.002" height="16.046"
                                        viewBox="0 0 16.002 16.046">
                                        <g id="iconfinder_076_LocationArrow_183205" transform="translate(524.983 -0.043)">
                                            <g id="Group" transform="translate(-669 -192)">
                                                <path id="Path_221" data-name="Path 221"
                                                    d="M144.017,200l16-7.957L152,208.089V200Zm0,0" fill-rule="evenodd" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <input type="text" class="form-control" placeholder="Cinema Location" name="cinemalocation" id="cinemalocation"
                                aria-label="Input group example" aria-describedby="btnGroupAddon2">
                        </div> -->
              <!-- <v-select :options="options" placeholder="Select option"></v-select> -->

              <div class="inputx">
                <svg width="16.002" height="16.046" viewBox="0 0 16.002 16.046">
                  <g id="iconfinder_076_LocationArrow_183205" transform="translate(524.983 -0.043)">
                    <g id="Group" transform="translate(-669 -192)">
                      <path id="Path_221" data-name="Path 221" d="M144.017,200l16-7.957L152,208.089V200Zm0,0" fill-rule="evenodd" />
                    </g>
                  </g>
                </svg>
                  <v-select v-model="selectedCinemaLocation" class="v-select-cust" :options="reportObject.cinemaLocationList"
                    placeholder="Cinema Location...">
                    <!-- <span slot="no-options">Sorry, No Options Here!</span> -->
                  </v-select>
              </div>
            </div>
            <div class="col-md-4 my-2">
              <div v-if="cinemaFalg" class="input-group custominputgroup">
                <div class="input-group-prepend">
                  <div class="input-group-text" id="btnGroupAddon2">
                    <svg width="18.866" height="20.046" viewBox="0 0 18.866 20.046">
                      <g id="iconfinder_cinema_103271" transform="translate(-16.667 -966.945)">
                        <path id="rect7568-8-4" d="M33.175,966.945l-2.653.774,1.29,3.5,2.543-.737ZM28.9,968.161l-3.464.995.074.147.663,1.806.59,1.548,3.464-.995ZM23.852,969.6l-3.058.884,1.327,3.5,3.058-.884-.774-1.99Zm-4.643,1.327-2.543.737,1.179,3.537,2.653-.774ZM17.846,975.2v10.612a1.177,1.177,0,0,0,1.179,1.179H34.354a1.177,1.177,0,0,0,1.179-1.179V975.2Zm2.358,2.358H33.175V981.1H20.2Z"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <input :disabled="cinemaFalg" v-model="CinemaName" type="text" class="form-control" placeholder="Cinema Name" id="cinemalist"
                  name="cinemaname" aria-label="Input group example" aria-describedby="btnGroupAddon2">
              </div>
              <!-- @hit="onChangeMovieDropdwon($event,props.row.thirdpartyID, props.row.Movie,props.index)" -->
              <div v-if="!cinemaFalg" class="inputx">
                <svg width="18.866" height="20.046" viewBox="0 0 18.866 20.046">
                  <g id="iconfinder_cinema_103271" transform="translate(-16.667 -966.945)">
                    <path id="rect7568-8-4" d="M33.175,966.945l-2.653.774,1.29,3.5,2.543-.737ZM28.9,968.161l-3.464.995.074.147.663,1.806.59,1.548,3.464-.995ZM23.852,969.6l-3.058.884,1.327,3.5,3.058-.884-.774-1.99Zm-4.643,1.327-2.543.737,1.179,3.537,2.653-.774ZM17.846,975.2v10.612a1.177,1.177,0,0,0,1.179,1.179H34.354a1.177,1.177,0,0,0,1.179-1.179V975.2Zm2.358,2.358H33.175V981.1H20.2Z"
                    />
                  </g>
                </svg>
                <v-select v-model="seletedCinema" class="v-select-cust" 
                label="CinemaName"
                :reduce="cinema => cinema.CinemaID"
                :options="reportObject.cinemaList" placeholder="Cinema Name..."></v-select>
              </div>
            </div>
            <div class="col-md-4 my-2">
              <div class="inputx">
                <svg width="22.202" height="22.674" viewBox="0 0 22.202 22.674">
                  <path id="iconfinder_38_110995" d="M36.992,44.941a9.684,9.684,0,1,0,19.368,0A11.431,11.431,0,0,0,54.943,39.8a3.6,3.6,0,0,1-.737-2.182,3.674,3.674,0,0,1,3.572-3.307h.236a1.181,1.181,0,0,0,0-2.362h-.236A5.667,5.667,0,0,0,51.8,36.674a8.7,8.7,0,0,0-5.121-1.417A9.685,9.685,0,0,0,36.992,44.941Zm8.5,0a1.181,1.181,0,1,1,1.181,1.181A1.181,1.181,0,0,1,45.495,44.941Zm-6.141,0a2.126,2.126,0,1,1,2.126,2.126A2.123,2.123,0,0,1,39.354,44.941Zm10.392,0a2.126,2.126,0,1,1,2.126,2.126A2.123,2.123,0,0,1,49.746,44.941Zm-5.2,5.2a2.126,2.126,0,1,1,2.126,2.126A2.123,2.123,0,0,1,44.55,50.137Zm0-10.393a2.126,2.126,0,1,1,2.126,2.126A2.123,2.123,0,0,1,44.55,39.744Z"
                    transform="translate(-36.992 -31.95)" fill="#A9A9A9" fill-rule="evenodd" />
                </svg>
                <v-select v-model="reportObject.selectedMovie" class="v-select-cust" 
                :reduce="movie => movie.MovieID"
                label="Title"
                :options="reportObject.moviesList" placeholder="Movie Name..."></v-select>

              </div>
            </div>
            <div class="col-md-4 my-2">
              <div class="input-group custominputgroup">
                <div class="input-group-prepend">
                  <div class="input-group-text" id="btnGroupAddon2">
                    <svg width="18" height="20" viewBox="0 0 18 20">
                      <path id="iconfinder_calendar_3671721" d="M1,4A2.006,2.006,0,0,1,3,2H17a2,2,0,0,1,2,2V18a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2ZM3,6V18H17V6ZM5,0H7V2H5Zm8,0h2V2H13ZM5,9H7v2H5Zm0,4H7v2H5ZM9,9h2v2H9Zm0,4h2v2H9Zm4-4h2v2H13Zm0,4h2v2H13Z" fill="#A9A9A9"
                        transform="translate(-1)" />
                    </svg>
                  </div>
                </div>
                <input v-model="reportObject.fromDate" type="date" id="todatepicker" class="form-control" placeholder="From Date: " aria-label="Input group example"
                  aria-describedby="btnGroupAddon2" name="fromdate">
              </div>
            </div>
            <div class="col-md-4 my-2">
              <div class="input-group custominputgroup">
                <div class="input-group-prepend">
                  <div class="input-group-text" id="btnGroupAddon2">
                    <svg width="18" height="20" viewBox="0 0 18 20">
                      <path id="iconfinder_calendar_3671721" d="M1,4A2.006,2.006,0,0,1,3,2H17a2,2,0,0,1,2,2V18a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2ZM3,6V18H17V6ZM5,0H7V2H5Zm8,0h2V2H13ZM5,9H7v2H5Zm0,4H7v2H5ZM9,9h2v2H9Zm0,4h2v2H9Zm4-4h2v2H13Zm0,4h2v2H13Z" fill="#A9A9A9"
                        transform="translate(-1)" />
                    </svg>
                  </div>
                </div>
                <input v-model="reportObject.toDate" type="date" id="fromdatepicker" class="form-control" placeholder="To Date: " aria-label="Input group example"
                  aria-describedby="btnGroupAddon2" name="todate">
              </div>
            </div>
            <div class="col-md-4 my-2 abc">
              <select v-model="reportObject.selectedScreenType" id="inputState" name="screening" class="form-control">
                <option disabled selected value="">Select Screening Type</option>
                <option value="all">All</option>
                <option v-for="(item, index) in reportObject.screenCategoryList" :key="index" :value="item.CategoryID">{{ item.CategoryTitle }}</option>
                <!-- <option value="mini Festival">Mini Festival</option>
                            <option value="Special Screening">Special Screening</option> -->
              </select>
            </div>

            <input v-on:click="onSearchDetails" type="button" class="btn btn-white mx-auto submit-btn px-5" value="SEARCH">
          </form>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import vSelect from "vue-select";
import ReportService from "@/services/CinemaAndDistributorService/CinemaAndDistributorReportService";

const options = [
  {
    value: "AL",
    label: "Alabama"
  },
  {
    value: "AK",
    label: "Alaska"
  },
  {
    value: "AS",
    label: "American Samoa",
    $isDisabled: true
  },
  {
    value: "AZ",
    label: "Arizona"
  },
  {
    value: "AR",
    label: "Arkansas"
  },
  {
    value: "CA",
    label: "California"
  }
];

export default {
  data() {
    return {
      optionsItem: options,
      selectedDistributor: "",
      selectedCinemaLocation: "",
      seletedCinema: "",
      userRoleName: "",
      partnerName: "Exhibitor",
      cinemaFalg: false,
      CinemaName: "",
      reportObject: {
        selectedScreening: "ALL_SCREENING",
        cinemaList: [],
        seletedCinema: "",
        moviesList: [],
        selectedMovie: "",
        cinemaLocationList: [],
        selectedCinemaLocation: "",
        fromDate: "",
        toDate: "",
        screenCategoryList: [],
        selectedScreenType: "",
        distributorList: [],
        selectedDistributor: ""
      },
      data: {
        selectedCinema: "",
        selectedLocation: "saiflo"
      },
      selectedValue: "selevvvvvvvv",
      value: 0
    };
  },
  components: {
    vSelect
  },
  computed: {},
  watch: {
    selectedDistributor(newvalue, oldvalue) {
      if (
        this.partnerName == "Distributor" &&
        newvalue.value !== oldvalue.value
      ) {
        console.log(">>>>>>>>", newvalue, oldvalue);
        // this.reportObject.moviesList = [];
        this.selectedCinemaLocation = "";
        this.seletedCinema = "";
        this.reportObject.selectedMovie = "";
        this.secureStore.set("TTUSR", newvalue.label);
        this.secureStore.set("TTUOID", newvalue.value);
        this.getCinemasMoviesList(0, newvalue.value || "");
        this.getCinemaLocationList(newvalue.value || "");
        this.getCinemasList(newvalue.value || "", "");
      }
    },
    selectedCinemaLocation(newvalue, oldvalue) {
      if (newvalue !== oldvalue) {
        console.log("here in locations", newvalue, oldvalue);
        this.seletedCinema = "";
        this.reportObject.selectedMovie = "";
        this.getCinemasList(this.selectedDistributor["value"] || "", newvalue);
      }
    },
    seletedCinema(newvalue, oldvalue) {
      if (newvalue.value !== oldvalue.value) {
        // console.log("here in Cinema", newvalue.length,"odldldldl>", oldvalue);
        this.reportObject.selectedMovie = "";
        if (newvalue.value == "") {
          this.getCinemasMoviesList(0, this.selectedDistributor["value"] || "");
        } else {
          this.getCinemasMoviesList(
            newvalue.value,
            this.selectedDistributor["value"] || ""
          );
        }
      }
    }
    // reportObject: {
    //   handler(newValue, oldValue) {
    //     }
    //   },
    //   deep: true
    // }
  },
  beforeMount() {
    console.log(
      'this.secureStore.get("TTUSR") :',
      this.secureStore.get("TTUSR")
    );
    this.userRoleName = this.secureStore.get("TTUSR")
      ? this.secureStore.get("TTUSR")
      : "";

    if (this.secureStore.get("TTURO") == "Cinema") {
      this.cinemaFalg = true;
    }
    if (this.secureStore.get("TTURO") == "Distributor") {
      this.partnerName = "Distributor";
      this.getDistributorList();
    }

    // If User is not Distributor than calling below otherwise i'm calling the function in watcher with distributor id
    if (this.secureStore.get("TTURO") != "Distributor") {
      this.getCinemasMoviesList(0, "");
      this.getCinemaLocationList("");
      this.getCinemasList("", "");
    }
    if (this.secureStore.get("TTUOID")) {
      this.selectedDistributor = {
        label: this.secureStore.get("TTUSR"),
        value: this.secureStore.get("TTUOID")
      };
    }

    // console.log("this.secureStore.get", this.secureStore.get("TTUSR"));
    // this.getCinemasMoviesList(0, this.selectedDistributor["value"] || "");
    this.getScreenCategoryList(null);
    this.secureStore.remove("SCFIL");
  },
  mounted: function() {},
  methods: {
    onChangeDropDown: function() {
      console.log(">>>>>>>>>>>>>>");
    },
    getCinemaLocationList: function(distributorId) {
      this.reportObject.cinemaLocationList = [];
      let payload = {
        DistributorID: distributorId
      };
      ReportService.getCinemasLocationData(payload)
        .then(response => {
          const { data } = response;
          if (data.Status && data.CinemaLocationList.length > 0) {
            this.reportObject.cinemaLocationList = data.CinemaLocationList;
            if (
              this.secureStore.get("TTURO") &&
              this.secureStore.get("TTURO") == "Cinema"
            ) {
              this.data.selectedLocation =
                data.CinemaLocationList.length > 0
                  ? data.CinemaLocationList[0]
                  : "";
            }
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Error", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCinemasList: function(distributorId, locationName) {
      // console.log(">>>>>>>>>>>>",distributorId, locationName)
      let payload = {
        DistributorID: distributorId,
        LocationName: locationName
      };
      this.reportObject.cinemaList = [];
      ReportService.getCinemasListData(payload)
        .then(response => {
          const { data } = response;
          if (data && data.Status && data.CinemaList.length > 0) {
            this.reportObject.cinemaList = data.CinemaList;
            // data.CinemaList.map(x => {
            //   this.reportObject.cinemaList.push({
            //     value: x.CinemaID,
            //     label: x.CinemaName
            //   });
            // });
            if (
              this.secureStore.get("TTURO") &&
              this.secureStore.get("TTURO") == "Cinema"
            ) {
              this.CinemaName =
                data.CinemaList.length > 0 ? data.CinemaList[0].CinemaName : "";
              this.reportObject.selectedCinema =
                data.CinemaList.length > 0 ? data.CinemaList[0].CinemaID : "";
            }
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Error", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCinemasMoviesList: function(cinemaId, distributorId) {
      this.value = 1;
      let payload = {
        CinemaID: cinemaId || 0,
        DistributorID: distributorId
      };

      this.reportObject.moviesList = [];
      ReportService.getCinemasMoviesListData(payload)
        .then(response => {
          const { data } = response;
          if (data && data.Status && data.MovieList.length > 0) {
            this.reportObject.moviesList = data.MovieList;
            // data.MoviesList.map(x => {
            //   this.reportObject.moviesList.push({
            //     value: x.MovieID,
            //     label: x.Title
            //   });
            // });
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Error", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    setData: function(payload) {},
    getScreenCategoryList: function(item) {
      let payload = {};
      ReportService.getCategoryListData(payload)
        .then(response => {
          const { data } = response;
          if (data && data.Status && data.CategoryList.length > 0) {
            // console.log("Category ", response);
            this.reportObject.screenCategoryList = data.CategoryList;
          } else {
            this.showMessageSnackbar("Screening type not found");
          }
        })
        .catch(error => {
          console.log("Error", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getDistributorList: function() {
      this.reportObject.distributorList = [];
      ReportService.getDistributorListData()
        .then(response => {
          const { data } = response;
          if (data && data.Status && data.DistributorList.length > 0) {
            this.reportObject.distributorList = data.DistributorList;
            // data.DistributorList.map(o => {
            //   this.reportObject.distributorList.push({
            //     label: o.DistributorName,
            //     value: o.DistributorID
            //   });
            // });
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Error", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    onSearchDetails: function() {
      if (this.reportObject.fromDate !== "" && this.reportObject.toDate == "") {
        this.showMessageSnackbar("Please Select To Date");
      } else if (
        this.reportObject.fromDate == "" &&
        this.reportObject.toDate !== ""
      ) {
        this.showMessageSnackbar("Please Select From Date");
      } else if (
        this.reportObject.fromDate !== "" &&
        this.reportObject.toDate !== "" &&
        this.reportObject.fromDate > this.reportObject.toDate
      ) {
        // console.log(
        //   " this.reportObject.fromDate > this.reportObject.toDate",
        //   this.reportObject.fromDate > this.reportObject.toDate
        // );
        this.showMessageSnackbar("From date can't be greater than to date");
      } else {
        this.callData();
      }
    },
    callData() {
      let payload = {
        cinemaLocation: this.selectedCinemaLocation || "",
        cinemaName: this.seletedCinema["value"] || "",
        movieName: this.reportObject.selectedMovie["value"] || "",
        fromDate: this.reportObject.fromDate || "",
        toDate: this.reportObject.toDate || "",
        screenCategoryType: this.reportObject.selectedScreenType || "",
        screening: this.reportObject.selectedScreening || "",
        distributor: this.selectedDistributor["value"] || ""
      };
      this.secureStore.set("SCFIL", payload);
      console.log("onsubmitsearchData ", payload);
      this.$store.dispatch("setReportFilter", payload);
      //   this.$router.push({
      //     path: "/cinemamasters/adminticketreportslist",
      //     query: {}
      //   });

      let routeData = this.$router.resolve({
        path: "/cinemamasters/adminticketreportslist",
        query: {}
      });
      window.open(routeData.href, "_blank");
    },
    // Show Message
    showMessageSnackbar: function(Message) {
      let x = document.getElementById("snackbar");
      // Add the "show" class to DIV
      x.className = "show";
      x.innerHTML = Message;
      // After 3 seconds, remove the show class from DIV
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    },
    onChangeScreening: function(name) {
      this.reportObject.selectedScreening = name;
    },
    onChangeCinemaLocationDropdwon: function(value, name) {
      if (name == "cinemaLocation") {
        this.reportObject.selectedCinemaLocation = value;
      } else if (name == "cinema") {
        this.reportObject.seletedCinema = value.CinemaID;
      } else if (name == "movie") {
        this.reportObject.selectedMovie = value.MovieID;
      }
      console.log("this.reportObject", this.reportObject);
    }
    // onChangeDistributor: function(event) {
    //   console.log("evenenene", event);
    // }
  },
  updated() {}
};
</script>
<style>
.inputx {
  overflow: visible;
}
.fixed-topOne {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
</style>



<style scoped>
.v-select-cust .dropdown-menu {
  max-height: 250px !important;
  overflow: scroll;
}
input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "";
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  opacity: 1;
}

.fixed-topOne {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
</style>
